@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";










































































.open-app-button {
  width: 96px;
  height: 32px;
  background: #2496ff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;

  span {
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 12px;
  }
}
