@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";























































































































































































































































































































































































::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: -5px;
}
::v-deep .van-tab {
  margin-right: 14px;
}
::v-deep .van-tab--active {
  color: #2496ff;
}
::v-deep .van-tabs__wrap {
  height: 42px;
}
::v-deep .van-tab--disabled {
  color: #646566;
}

.game-detail-container {
  background: #f6f6f6;

  .tabs {
    padding: 0 15px;
    background: #fff;
  }
}
.fixed-bottom {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 96px;
  z-index: 998;
}
