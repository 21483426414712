@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































































































































































































::v-deep .van-popup {
  border-radius: 10px 10px 0px 0px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}

.wrapper {
  position: relative;
  padding: 28px 34px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close-icon {
    position: absolute;
    top: 22px;
    right: 18px;
  }
  .game {
    width: 100%;
    display: flex;
    align-items: center;
    .game-icon {
      width: 46px;
      height: 46px;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      margin-right: 12px;
    }
    .game-tip {
      font-size: 18px;
      font-weight: 600;
      color: #2c3a4d;
      line-height: 25px;
      margin-bottom: 4px;
      .active {
        color: #2496ff;
      }
    }
    .game-name {
      font-size: 12px;
      font-weight: 400;
      color: #2496ff;
      line-height: 17px;
    }
  }
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #2c3a4d;
    line-height: 25px;
    .active {
      color: #2496ff;
    }
  }
  .logo {
    width: 74px;
    height: 74px;
    margin: 24px auto 16px;
  }
  .slogan {
    width: 141px;
    height: 18px;
    display: flex;
    margin-bottom: 17px;
  }
  .list {
    width: 271px;
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    .item-icon {
      width: 50px;
      height: 50px;
      display: flex;
      margin-bottom: 8px;
    }
    .item-name {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }
  .btn {
    width: 307px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    margin-bottom: 16px;
    background: #2496ff;
  }
  .tip {
    height: 15px;
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    a {
      color: #1383eb;
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
