@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
























































































































































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.game-abstract {
  width: 100%;
  // min-height: 198px;
  // 198-42=156
  min-height: 156px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 15px 15px 19px;
  margin-bottom: 7px;
  .info-row {
    display: flex;
    .icon-wrap {
      width: 91px;
      height: 88px;
      display: flex;
      justify-content: center;
      position: relative;
      margin-right: 8px;
    }
    .icon {
      width: 88px;
      height: 88px;
      border-radius: 18px;
      overflow: hidden;
    }
    .rank {
      width: 97px;
      height: 24px;
      background-image: url("~@/assets/images/bg_bangdan.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      padding-top: 2px;
      box-sizing: border-box;
      .rank-icon {
        display: flex;
        width: 11px;
        height: 11px;
      }
      .arrow-icon {
        display: flex;
        height: 9px;
        width: 9px;
      }
      .rank-text {
        height: 12px;
        font-size: 8px;
        font-weight: bold;
        color: #ffffff;
        line-height: 12px;
        white-space: nowrap;
        padding-top: 2px;
        margin: 0 3px;
      }
    }
    .info {
      margin-right: 15px;
      width: calc(100% - 185px);
    }
    .name {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      margin-bottom: 6px;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        height: 16px;
        line-height: 16px;
        background: #ffffff;
        padding: 0px 5px;
        padding-top: 2px;
        font-size: 10px;
        font-weight: 400;
        color: #333333;
        margin-right: 4px;
        margin-bottom: 4px;
        &:after {
          border-radius: 8px;
          border-color: #c2c6cc;
        }
      }
    }
    .more-tag-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 18px;
      cursor: pointer;
      &:after {
        border-radius: 8px;
        border-color: #c2c6cc;
      }
    }
    .score {
      width: 62px;
      height: 52px;
      background-image: url("~@/assets/images/icon_pingfen.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: bold;
      color: #ffffff;
      line-height: 23px;
    }
  }
  .events {
    width: auto;
    min-height: 22px;
    background-color: rgba(244, 245, 246, 0.6);
    border-radius: 11px;
    font-size: 10px;
    font-weight: 400;
    color: #999999;
    padding: 4px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 16px;
    .content {
      max-width: 90%;
      padding-top: 2px;
      @include ellipsis();
    }
    span {
      line-height: 14px;
    }
  }
  .active {
    background-color: rgba(36, 150, 255, 0.1);
    color: #2496ff;
  }
  .disabled-download-btn {
    width: 100%;
    height: 42px;
    background: #ededed;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #999999;
    line-height: 42px;
    text-align: center;
    margin: 16px 0 9px;
  }
  .download-btn {
    width: 100%;
    height: 42px;
    background: #2496ff;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    text-align: center;
    margin: 16px 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-wrap {
      display: flex;
      width: 14px;
      height: 18px;
      margin-right: 4px;
    }
  }
  .yellow {
    background: #ffa142;
  }
  .off {
    color: #999999;
    background: #ededed;
  }
  .tip {
    width: 100%;
    height: 15px;
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    text-align: center;
  }
}
.tips-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.75);
}
.wechat_tip {
  width: 196px;
  height: 124px;
  position: absolute;
  top: 0;
  right: 0;
}
